<!-- 邀请好友 -->
<template>
  <div class="invate-page">
    <img class="bg-img" src="../assets/images/invate.png" alt="" />
    <div class="content">
      <div class="invate-box">
        <div class="icon-ques" @click="showRuleBomb = true">
          <img src="../assets/images/icon-quer-nor.svg" />
        </div>
        <div class="title theme-word-color font-b font-bold">
          {{ $i18n.t("in_head_copywriting") }}
        </div>
        <div class="sub-title font-m">{{ $i18n.t("in_head_copywriting2") }}</div>
        <div class="invate-steps">
          <div class="step-item">
            <div class="img">
              <img src="../assets/images/icon-step1.svg" alt="" />
            </div>
            <div class="word">{{ $i18n.t("in_copy_link") }}</div>
          </div>
          <div class="line">
            <div class="arraw"></div>
          </div>
          <div class="step-item">
            <div class="img">
              <img src="../assets/images/icon-step2.svg" alt="" />
            </div>
            <div class="word">{{ $i18n.t("in_share_friends") }}</div>
          </div>
          <div class="line">
            <div class="arraw"></div>
          </div>
          <div class="step-item">
            <div class="img">
              <img src="../assets/images/icon-step3.svg" alt="" />
            </div>
            <div class="word">{{ $i18n.t("in_obtaining_commission") }}</div>
          </div>
        </div>
        <div
          v-show="urlLink"
          class="btn copy-btn font-m"
          id="tag-copy"
          :data-clipboard-text="urlLink"
          @click="copyActiveCode"
        >
          {{ $i18n.t("in_copy_link") }}
        </div>
      </div>
      <div class="team-info">
        <div class="title font-m">{{ $i18n.t("in_my_team") }}</div>
        <div class="team-data">
          <div class="data-item">
            <div class="word">{{ $i18n.t("in_team_size") }}</div>
            <div class="price">
              <div class="num font-b">{{ teamInfo.teamSize }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("in_direct_reports") }}</div>
            <div class="price">
              <div class="num font-b">{{ teamInfo.directSize }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("in_total_income") }}(ETH)</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ teamInfo.returnsETH | fixed_6 }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("in_today_earnings") }}(ETH)</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ teamInfo.todayReturnsETH | fixed_6 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="record-list">
        <div class="status-tab">
          <div
            class="status-item"
            :class="{active: activeIndex === index}"
            v-for="(item, index) in tabList"
            :key="index"
            @click="handleChangeTab(index)"
          >
            <span>{{ index === 0 ? $i18n.t(item) : item }}</span>
          </div>
        </div>
        <div class="list-title">
          <div class="label font-m">{{ $i18n.t("in_address") }}</div>
          <div class="value font-m">{{ $i18n.t("in_time") }}</div>
        </div>
        <div class="list">
          <div class="list-item" v-for="(item, index) in recordList[activeIndex]" :key="index">
            <div class="label">{{ item.address | capitalize }}</div>
            <div class="value">{{ $moment(handleTime(item.createdAt)).format("YYYY-MM-DD HH:mm:ss") }}</div>
          </div>
          <div class="cus-empty" v-if="recordList[activeIndex].length === 0">
            <span>{{ $i18n.t("in_no_data") }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 收入规则 -->
    <van-popup class="rule-popup" round v-model="showRuleBomb">
      <div class="rule-box">
        <div class="close" @click="showRuleBomb = false">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="title font-m">{{ $i18n.t("in_revenue_rules") }}</div>
        <div class="rule-list">
          <div class="rule-title font-m">
            <div class="label">{{ $i18n.t("in_ask") }}</div>
            <div class="level">level1</div>
            <div class="level">level2</div>
            <div class="level">level3</div>
          </div>
          <div class="list">
            <div class="rule-item font-m" v-for="item in ruleList" :key="item.value">
              <div class="label">{{ item.value }} USDT</div>
              <div class="level">{{ item.lev1 }}%</div>
              <div class="level">{{ item.lev2 }}%</div>
              <div class="level">{{ item.lev3 }}%</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      teamInfo: {
        directSize: 0, // 直接下属
        returnsETH: 0, // eth总收益
        returnsUSD: 0, // usd总收益
        teamSize: 0, // 团队规模
        todayReturnsETH: 0, // 当日eth总收益
        todayReturnsUSD: 0, // 当日usd总收益
      },
      activeIndex: 0,
      tabList: ["in_all", "level1", "level2", "level3"],
      recordList: [[], [], [], []],
      urlLink: "",
      userInfo: {},
      showRuleBomb: false,
      ruleList: [],
    };
  },
  filters: {
    fixed_6: function (value) {
      if (!value && value !== 0) return 0;
      return value.toFixed(6);
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      // return value.charAt(0).toUpperCase() + value.slice(1)
      return value.substring(0, 6) + "*******" + value.substring(value.length - 6);
    },
  },
  created() {
    this.getUserInfo();
    this.getTeam();
    this.getRecord();
    this.getRule();
  },
  mounted() {},
  methods: {
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    handleChangeTab(index) {
      this.activeIndex = index;
    },
    copyActiveCode() {
      if (!this.userInfo.user) {
        this.$toast(this.$i18n.t("in_copy_again"));
        return;
      }
      const clipboard = new Clipboard("#tag-copy");
      clipboard.on("success", () => {
        this.$toast(this.$i18n.t("in_copy_success"));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast(this.$i18n.t("in_copy_failed"));
        // 释放内存
        clipboard.destroy();
      });
    },
    // 获取规则
    async getRule() {
      const res = await this.$http.getUserRule();
      if (res.code === 0 && res.data.list) {
        this.ruleList = res.data.list.map((v) => {
          const [lev1, lev2, lev3] = v.incomes;
          return {
            value: v.require,
            lev1,
            lev2,
            lev3,
          };
        });
      }
    },
    // 我的团队
    async getTeam() {
      const res = await this.$http.getUnderInvite();
      Object.assign(this.teamInfo, res);
    },
    // 我的团队
    getRecord() {
      const allList = [];
      const valList = ["all", "level1", "level2", "level3"];
      for (let index = 0; index < valList.length; index++) {
        const element = valList[index];
        allList.push(this.$http.getUserInviteRecord({keyword: element}));
      }
      Promise.all(allList).then((res) => {
        this.recordList = res.map((v) => {
          return v.data.list || [];
        });
      });
    },
    getUserInfo() {
      let that = this;
      this.$http.getUserInfo().then((res) => {
        if (res.code === 0) {
          that.userInfo = res.data;
          that.urlLink = `${location.origin}/login?u=${that.userInfo.user.ID}`;
        }
      });
    },
  },
};
</script>

<style lang="less">
.invate-page {
  width: 100%;
  position: relative;
  background: #f9f9f9;
  padding-top: 45vh;
  padding-bottom: 2rem;
  .bg-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .invate-box {
    position: relative;
    width: 90%;
    border-radius: 0.32rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 201, 171, 0.1);
    background: #fff;
    overflow: hidden;
    padding: 0.48rem 0.48rem;
    margin-left: 5%;
    .icon-ques {
      position: absolute;
      right: 0.24rem;
      top: 0.2rem;
    }
    .title {
      line-height: 0.6rem;
    }
    .sub-title {
      line-height: 0.36rem;
      color: #666;
    }
    .invate-steps {
      display: flex;
      margin-top: 0.3rem;
      .step-item {
        width: 24%;
        .img {
          img {
            width: 0.64rem;
            height: 0.64rem;
          }
        }
        .word {
          color: #333;
          font-size: 0.24rem;
          line-height: 0.36rem;
          margin-top: 0.12rem;
        }
      }
      .line {
        width: 14%;
        height: 0;
        border-top: 1px dashed #00c9ab;
        position: relative;
        margin-top: 0.3rem;
        .arraw {
          width: 0.2rem;
          height: 0.2rem;
          background: url("../assets/images/caret-right.svg");
          background-size: 100%;
          position: absolute;
          left: calc(50% - 0.1rem);
          top: -0.1rem;
        }
      }
    }
    .copy-btn {
      height: 0.72rem;
      line-height: 0.72rem;
      padding: 0 0.76rem;
      margin-top: 0.48rem;
      font-weight: 500;
      background: linear-gradient(
        90deg,
        #00cdff 0%,
        #2993ff 24%,
        #458fff 49%,
        #52d3ba 86%,
        #63ff92 100%
      );
    }
  }
  .team-info {
    background: #fff;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    position: relative;
    z-index: 1;
    margin-top: 0.48rem;
    padding: 0 0.48rem;
    margin-bottom: 0.24rem;
    .title {
      color: #333;
      line-height: 0.6rem;
      padding: 0.48rem 0 0.3rem 0;
      text-align: left;
      font-size: 0.4rem;
    }
    .team-data {
      display: flex;
      flex-wrap: wrap;
      .data-item {
        width: 50%;
        margin-bottom: 0.3rem;
      }
    }
  }
  .record-list {
    background: #fff;
    padding: 0.48rem 0.48rem;
    .status-tab {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.48rem;
      .status-item {
        color: #999;
        font-size: 0.32rem;
        line-height: 0.48rem;
        span {
          display: inline-block;
          position: relative;
        }
      }
      .status-item.active {
        font-family: myMidFont;
        color: #333;
        span::after {
          content: "";
          display: block;
          width: 0.5rem;
          height: 2px;
          background: linear-gradient(90deg, #00cdff 0%, #32f5db 49%, #63ff92 100%);
          border-radius: 2px;
          position: absolute;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
    }
    .list-title {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 0.28rem;
      line-height: 0.36rem;
    }
    .list {
      padding: 0.1rem 0;
      .list-item {
        display: flex;
        justify-content: space-between;
        line-height: 0.36rem;
        padding: 0.08rem 0;
        .label {
          color: #00c9ab;
        }
        .value {
          color: #333;
        }
      }
      .cus-empty {
        color: #999;
        padding-top: 10px;
      }
    }
  }
  .rule-popup {
    width: 88%;
    .rule-box {
      padding: 0.3rem 0.24rem;
      position: relative;
      .close {
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
        img {
          width: 0.48rem;
          height: 0.48rem;
        }
      }
      .title {
        font-size: 0.36rem;
        color: #333;
        margin-bottom: 0.3rem;
      }
      .rule-list {
        overflow: hidden;
        .label {
          width: 30%;
        }
        .level {
          width: 23.3%;
        }
        .rule-title {
          display: flex;
          height: 0.88rem;
          line-height: 0.88rem;
          border-top-left-radius: 0.16rem;
          border-top-right-radius: 0.16rem;
          background: linear-gradient(90deg, #00cdff 0%, #32f5db 49%, #63ff92 100%);
          div {
            color: #fff;
            font-size: 0.28rem;
          }
        }
        .list {
          border: 1px solid #eee;
          border-top: none;
          border-bottom-left-radius: 0.16rem;
          border-bottom-right-radius: 0.16rem;
        }
        .rule-item {
          display: flex;
          line-height: 0.8rem;
          height: 0.8rem;
          border-top: 1px solid #eee;
          font-size: 0.24rem;
          .label {
            color: #333;
          }
          .level {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
